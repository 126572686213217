<template>
  <div>
    <CRow>
      <CCol lg="12">
        <studentProfileCard />
      </CCol>
      <!-- <CCol lg="4">
        <CloudStorageChart />
        <QuickNoteForm />
      </CCol> -->
    </CRow>
  </div>
</template>

<script>
import StatWidgetBox from "../app-main-views/stats-widgets/StatWidgetBox.vue";
import WidgetStats from "../app-main-views/stats-widgets/WidgetStats";
import UserProfileCard from "../app-main-views/user-profile-card/UserProfileCard";
import studentProfileCard from "../app-main-views/user-profile-card/studentProfileCard";
import CloudStorageChart from "../app-main-views/cloud-storage-chart/CloudStorageChart";
import QuickNoteForm from "../app-main-views/forms/QuickNoteForm";


export default {
  name: "Dashboard",
  data() {
      return{
          subjects: [
        {
          subjectName: "Marathi",
          teacherName: "Ms. Aruna Patil",
          totalUploads: 4,
          subjectColor: "warning"
        },
        {
          subjectName: "Hindi",
          teacherName: "Mr. Vinit Desai",
          totalUploads: 6,
          subjectColor: "success"
        },
        {
          subjectName: "English",
          teacherName: "Mr. B. V. Patil",
          totalUploads: 5,
          subjectColor: "primary"
        },
        {
          subjectName: "Social Science",
          teacherName: "Mr. Chandu Mali",
          totalUploads: 3,
          subjectColor: "secondary"
        },
        {
          subjectName: "Maths",
          teacherName: "Mr. Chandu Mali",
          totalUploads: 7,
          subjectColor: "danger"
        },
        {
          subjectName: "Science",
          teacherName: "Ms. Sangeeta Deshpande",
          totalUploads: 9,
          subjectColor: "info"
        },
        {
          subjectName: "Other Material",
          teacherName: "District Authorities",
          totalUploads: 2,
          subjectColor: "secondary"
        }
      ]
    
  }
  },
  components: {
    WidgetStats,
    UserProfileCard,
    studentProfileCard,
    CloudStorageChart,
    QuickNoteForm,
    StatWidgetBox
  },
  created(){
    let user_id = this.$session.get('user_id');
    let user_role_id = this.$session.get('user_role_id');
    if(user_id == undefined || user_id == null)
    {
      return '/';
      //this.$router.push('/auth/login');
    }
    if(user_role_id != '8290f938-b20a-42e3-c201-08d8f3241655')
    {
      return '/';
    }  
          
  }
};
</script>
