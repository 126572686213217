<template>
  <div class="card card-profile text-center">
    <div
      class="top-block"
      :style="{ backgroundImage: `url(${profile.headerImg})` }"
    >
      <h4 class="py-3 pt-5">{{ profile.name }}</h4>
      <p class="px-5 py-3 about">
        {{ profile.about }}
      </p>
    </div>

    <div class="card-block">
      <img alt="" class="card-img-profile" v-bind:src="profile.profileImg" />
    </div>

    <!-- <div class="row pb-3 ">
      <div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
        <div class="row">
          <div class="col">
            <h5>Students</h5>
            <h5>{{ profile.followers }}</h5>
          </div>
          <div class="col">
            <h5>Contents</h5>
            <h5>{{ profile.following }}</h5>
          </div>
          <div class="col">
            <h5>Sharing Score</h5>
            <h5>{{ profile.notes }}</h5>
          </div>
        </div>
      </div>
    </div> -->
    <div class="row pb-3 ">
    <div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
      <table class="table tabe-bordered" style="text-align:'left';align-items:'left'" v-if="user == '6b82d8f3-e879-42de-a8ff-2426b61c9ab9'">
         <tr>
             <th style="text-align:'left'">School Name</th><td></td><td>MH School</td>
         </tr>
         <tr>
             <th style="text-align:'left'">School Email</th><td></td><td>Test@gmail.com</td>
         </tr>
         <tr>
             <th style="text-align:'left'">Contact No.</th><td></td><td>7536416123</td>
         </tr>
         <tr>
             <th style="text-align:'left'">Address</th><td></td><td>Adress</td>
         </tr>
      </table>
      <table class="table tabe-bordered" style="text-align:'left';align-items:'left'" v-if="user == '6b82d8f3-e879-42de-a8ff-2426b61c9ac9'">
        
      </table>
      <table class="table tabe-bordered" v-if="user == '8290f938-b20a-42e3-c201-08d8f3241655'">
         <tr>
             <th>Parent Name</th><td></td><td>Test1</td>
         </tr>
         <tr>
             <th>Parent Email</th><td></td><td>Test@gmail.com</td>
         </tr>

         <tr>
             <th>Parent Contact No.</th><td></td><td>7536416123</td>
         </tr><tr>
             <th>Address</th><td></td><td>Adress</td>
         </tr>
      </table>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user:"",
      profile: {
        name: localStorage.getItem('user_name'),
        about:
          "Class - 5(B)",
        profileImg: "https://joeschmoe.io/api/v1/Aruna%20Patil",
        headerImg: "http://placeimg.com/640/480/any",
        followers: "9882",
        following: "1397",
        notes: "17200",
      },
    };
  },
  name: "UserProfileCard",
  components: {},
  created(){
    this.user  = localStorage.getItem('user_role_id');
    if(this.user == '6b82d8f3-e879-42de-a8ff-2426b61c9ab9'){
      this.profile.about = "MH School Affliated to MahaVidya"
    }
    if(this.user == '6b82d8f3-e879-42de-a8ff-2426b61c9ac9'){
      this.profile.about = "Welcome To Super Admin Panel"
    }
  }
};
</script>

<style scoped>
.card-profile {
  width: 100%;
  background-color: #e5e5e5;
  border-radius: 0;
  border: 0;
}
.card-profile .card-img-top {
  border-radius: 0;
}
.card-profile .card-img-profile {
  max-width: 150px;
  border-radius: 50%;
  margin-top: -95px;
  margin-bottom: 35px;
  border: 5px solid #e6e5e1;
}

.top-block {
  height: 300px;
  color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
}

.top-block .about {
  font-size: 1.1em;
}
</style>
